<template>
    <div>
         <vx-card :title="`🏢🚪 Crear ${$tc('message.apartment', 1, user.countryCode)}`" title-color="black">
      <p
        class="mb-1"
      >Ingrese todos los datos a continuación:</p>
    </vx-card>
    <br/>
    <formularioCrearApartamento/>
    </div>
</template>

<script>
import { mapState } from "vuex";
import formularioCrearApartamento from './CreatePropertyForm'

export default {
    data(){
        return{
            nivelID:''
        }
    },
    components:{
        formularioCrearApartamento
    },
    computed: {
    ...mapState("auth", ["user"]),
    },
    mounted(){
            this.nivelID = localStorage.nivelID
    }
}
</script>

<style lang="scss" scoped>

</style>
