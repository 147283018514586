<template>
  <div>
    <vx-card>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h3 class="mt-4 mb-4">Nuevo propiedad</h3>
      </vs-col>
      <br />
      <div class="p-2">
        <vs-row>
          <vs-col>
            <vs-chip color="success" class="m-2">Paso 1</vs-chip>
            <div>
              <h6 class="mt-3">Información basica:</h6>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider></vs-divider>
      </div>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <p class="p-2">Tipo de propiedad:</p>
            <v-select
              class="px-2"
              v-model="type"
              :options="propertiesTypes"
            ></v-select>
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <p class="p-2">Seleccione estado:</p>
            <v-select
              class="px-2"
              v-model="status"
              :options="propertiesStatus"
            ></v-select>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              class="w-full px-2"
              label="Identificador de propiedad"
              color="primary"
              v-model="unitNumber"
              v-validate="'required'"
              name="Nombre_Proyecto"
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              class="w-full px-2"
              label="Numero de Nivel:"
              min="0"
              color="primary"
              v-model="level"
              v-validate="'required'"
              name="Nivel"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              class="w-full px-2"
              label="Número de torre"
              color="primary"
              v-model.trim="towerNumber"
              v-validate="'required'"
              name="towerNumber"
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              class="w-full px-2"
              label="Vista:"
              color="primary"
              v-model.trim="viewStreet"
              v-validate="'required'"
              name="viewStreet"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-bed"
              type="number"
              min="0"
              label="Dormitorios"
              color="primary"
              v-model.number="rooms"
              v-validate="'required'"
              name="Dormitorios"
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-toilet"
              label="Baños"
              color="primary"
              type="number"
              min="0"
              v-model.number="bathrooms"
              name="Baños"
            />
          </div>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-money-bill"
              label="Precio - Ingresar valor sin comas"
              color="primary"
              type="number"
              min="0"
              v-model.number="price"
              v-validate="'required'"
              name="Precio"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <p class="p-2">Seleccione topología:</p>
            <v-select
              class="px-2"
              taggable
              v-model="unitType"
              :options="unitTypes"
              :create-option="(type) => ({ label: type, value: null })"
            ></v-select>
          </div>
        </vs-col>
      </vs-row>
      <br />
      <vs-row>
        <vs-col>
          <vs-chip color="success" class="m-2">Paso 2</vs-chip>
          <div>
            <h6 class="mt-3">Atributos extras:</h6>
          </div>
        </vs-col>
      </vs-row>
      <vs-divider></vs-divider>

      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="balcony" class="p-2">Balcón</label>
            <input type="checkbox" id="balcony" v-model="balcony" />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="kitchenFurniture" class="p-2">Muebles de Cocina</label>
            <input
              type="checkbox"
              id="kitchenFurniture"
              v-model="kitchenFurniture"
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="closets" class="p-2">Closets</label>
            <input type="checkbox" id="closets" v-model="closets" />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="laundry" class="p-2">Lavandería</label>
            <input type="checkbox" id="laundry" v-model="laundry" />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="homeAppliances" class="p-2">Electrodomésticos</label>
            <input
              type="checkbox"
              id="homeAppliances"
              v-model="homeAppliances"
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1">
          <div class="w-full sm:w-full mt-2">
            <label for="garden" class="p-2">Jardín</label>
            <input type="checkbox" id="garden" v-model="garden" />
          </div>
        </vs-col>
      </vs-row>

      <br />
      <vs-row>
        <vs-col>
          <vs-chip color="success" class="m-2">Paso 3</vs-chip>
          <div>
            <h6 class="mt-3">Áreas mts2:</h6>
          </div>
        </vs-col>
      </vs-row>
      <vs-divider></vs-divider>
      <br />
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-vector-square"
              type="number"
              label="Área habitable"
              color="primary"
              v-model.number="livingArea"
              v-validate="'required'"
              name="living_area"
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-vector-square"
              type="number"
              label="Área construida"
              color="primary"
              v-model.number="constructionArea"
              v-validate="'required'"
              name="constructionArea"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-vector-square"
              type="number"
              label="Área jardin"
              color="primary"
              v-model.number="gardenArea"
              v-validate="'required'"
              name="garden_area"
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-vector-square"
              type="number"
              label="Área balcón"
              color="primary"
              v-model.number="balconyArea"
              v-validate="'required'"
              name="balcony_area"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-vector-square"
              type="number"
              label="Área terraza"
              color="primary"
              v-model.number="terraceArea"
              v-validate="'required'"
              name="terrace_area"
            />
          </div>
        </vs-col>
        <vs-col class="sm:w-full lg:w-1/2 p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-vector-square"
              type="number"
              label="Área total"
              color="primary"
              v-model.number="userTotalArea"
              v-validate="'required'"
              name="total_area"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <br />
          <vs-row>
            <vs-col class="sm:w-full lg:w-1/2 p-1"></vs-col>
            <vs-col class="sm:w-full lg:w-1/2 p-1">
              <vs-button
                color="success"
                @click="createProperty()"
                :disabled="!fieldsReady"
                icon-pack="feather"
                icon="icon-save"
                type="filled"
                ref="loadableButton"
                id="button-with-loading"
                class="vs-con-loading__container w-full"
                >Guardar Apto. {{ unitNumber }}</vs-button
              >
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import api from "../services/property.api";
import { unitTypeApi } from "../../projects/services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      propertiesTypes: [],
      type: "",
      unitNumber: "",
      status: "",
      level: "",
      price: 0,
      rooms: 0,
      bathrooms: 0,
      balcony: false,
      kitchenFurniture: false,
      closets: false,
      laundry: false,
      homeAppliances: false,
      garden: false,
      unitType: "",
      unitTypes: [],
      livingArea: 0,
      terraceArea: 0,
      gardenArea: 0,
      balconyArea: 0,
      totalArea: 0,
      constructionArea: 0,
      towerNumber: "",
      viewStreet: "",
      propertiesStatus: [],
    };
  },
  components: {
    "v-select": vSelect,
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;

    const propertiesStatus = await api.getPropertyStatus();

    this.propertiesStatus = propertiesStatus.filter(
      (status) => status.id === "AVAILABLE" || status.id === "BLOCKED"
    );
    this.propertiesStatus = this.propertiesStatus.map((status) => ({
      label: status.name,
      value: status.id,
    }));

    let propertiesTypes = await api.getProperyTypes();

    propertiesTypes = propertiesTypes.filter(
      (i) => i.id !== "WAREHOUSE" && i.id !== "PARKING"
    );

    this.propertiesTypes = propertiesTypes.map((obj) => {
      return { label: obj.name, value: obj.id };
    });

    const unitTypes = await unitTypeApi.list(projectId);

    this.unitTypes = unitTypes.map((obj) => {
      return { label: obj.type, value: obj.id };
    });
  },
  computed: {
    ...mapState("auth", ["user"]),
    fieldsReady() {
      if (
        !this.type ||
        !this.status ||
        !this.unitNumber ||
        !this.rooms ||
        !this.price ||
        !this.level ||
        !this.unitType
      ) {
        return false;
      } else {
        return true;
      }
    },
    userTotalArea: {
      get() {
        if (this.totalArea > 0) {
          return this.totalArea;
        }

        const constructionArea = this.constructionArea || 0;
        const terraceArea = this.terraceArea || 0;
        const gardenArea = this.gardenArea || 0;
        const balconyArea = this.balconyArea || 0;

        return (
          constructionArea +
          terraceArea +
          gardenArea +
          balconyArea
        ).toFixed(2);
      },
      set(value) {
        this.totalArea = parseFloat(value);
      },
    },
  },
  methods: {
    openLoadingContained() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45,
      });
    },
    closeLoading() {
      this.$vs.loading.close("#button-with-loading > .con-vs-loading");
    },
    async createProperty() {
      this.openLoadingContained();
      const projectId = this.$store.getters["project/currentProject"];
      try {
        let unitTypeId = null;
        const unitType = await unitTypeApi.get(
          projectId,
          this.unitType.label.trim()
        );

        if (unitType) {
          unitTypeId = unitType.id;
        } else {
          const newUnitType = await unitTypeApi.add(
            projectId,
            this.unitType.label.trim()
          );
          unitTypeId = newUnitType.id;
        }

        await api.createMainProperty(projectId, {
          type: this.type.value || "APARTMENT",
          status: this.status.value || "BLOCKED",
          unitNumber: this.unitNumber,
          level: this.level || "1",
          tower: this.towerNumber,
          view: this.viewStreet,
          bedrooms: this.rooms,
          bathrooms: this.bathrooms,
          price: this.price,
          unitType: unitTypeId,
          balcony: this.balcony,
          kitchenFurniture: this.kitchenFurniture,
          closet: this.closets,
          laundry: this.laundry,
          garden: this.garden,
          homeAppliances: this.homeAppliances,
          livingArea: this.livingArea,
          terraceArea: this.terraceArea,
          balconyArea: this.balconyArea,
          gardenArea: this.gardenArea,
          constructionArea: this.constructionArea,
          totalArea: this.totalArea,
        });

        this.unitNumber = "";
        this.level = "";
        this.rooms = 0;
        this.bathrooms = 0;
        this.price = 0;

        this.balcony = false;
        this.kitchenFurniture = false;
        this.closets = false;
        this.laundry = false;
        this.homeAppliances = false;
        this.garden = false;

        this.livingArea = 0;
        this.terraceArea = 0;
        this.gardenArea = 0;
        this.balconyArea = 0;
        this.totalArea = 0;
        this.constructionArea = 0;

        this.unitType = "";
        this.towerNumber = "";
        this.viewStreet = "";

        this.status = "";
        this.type = "";

        this.$vs.notify({
          time: 6000,
          title: `Nueva propiedad creada! 🏢🚀`,
          text: `Fue creado correctamente.`,
          color: "success",
        });
        this.$emit("closePopup");
      } catch (e) {
        console.log(e);
        sentryCaptureException(e, "property");
        this.$vs.notify({
          title: `No ha sido posible crear la propiedad`,
          text: `No ha sido posible crear la propiedad`,
          color: "danger",
        });
      }

      this.closeLoading();
    },
  },
};
</script>

<style lang="scss">
.fa-money-bill:before,
.fa-bed:before,
.fa-warehouse:before,
.fa-toilet:before,
.fa-bed:before,
.fa-parking:before,
.fa-door-closed:before,
.fa-door-open:before,
.fa-tshirt:before,
.fa-mitten:before,
.fa-tag:before,
.fa-dollar-sign:before,
.fa-seedling:before,
.fa-hand-holding-usd:before,
.fa-vector-square:before,
.fa-person-booth:before,
.fa-plus-square:before,
.fa-flag:before,
.fa-lock:before {
  margin: 4px;
}

.vs__dropdown-toggle {
  height: 46.777px;
}
</style>
